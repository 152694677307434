<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-account menu="password"></submenu-account>
       
    <div class="submenu-content">
      
        <h2>Change Password</h2>
        
        <div v-if="customer">
          <div class="mt-4 mb-4">
            <div>Customer ID: {{ custno }}</div>
            <div>Legal Business Name: {{ company }}</div>
            <div>User ID: {{ custno }}</div>
          </div>

          
          <div v-if="message">
          <div class="alert alert-dismissible fade show" :class="status" role="alert">
            {{ message }}
          </div>
          </div>
          
          <form @submit.prevent="handleSubmit">
            <div class="col-md-3 mb-2" :class="{'alert alert-danger': data.current_password.error}">
              <label class="form-label">Current Password <span class="red">*</span></label>
              <div v-if="data.current_password.error">This field is required</div>
              <input type="password" class="form-control" v-model="data.current_password.input" ref="data.current_password.input">
            </div>

            <div class="col-md-3 mb-2" :class="{'alert alert-danger': data.new_password.error}">
              <label class="form-label">New Password <span class="red">*</span></label>
              <div v-if="data.new_password.error">{{data.new_password.error}}</div>
              <input type="password" class="form-control" v-model="data.new_password.input" ref="data.new_password.input">
            </div>

            <div class="col-md-3 mb-2" :class="{'alert alert-danger': data.new_password2.error}">
              <label class="form-label">Retype New Password <span class="red">*</span></label>
              <div v-if="data.new_password2.error">Retyped password is not consistent.</div>
              <input type="password" class="form-control" v-model="data.new_password2.input" ref="data.new_password2.input">
            </div>

            

              <!-- <div class="col-3 mb-1">
                  <input type="password" v-model="current_password" class="form-control" required placeholder="Current Password">
              </div>
              <div class="col-3 mb-1">
                  <input type="password" v-model="new_password" class="form-control" required placeholder="New Password">
              </div>
              <div class="col-3 mb-1">
                <div v-if="error" 
                    :class="{'alert alert-danger': error}">{{error}}</div>
                  <input type="password" v-model="new_password2" class="form-control" required placeholder="Retype New Password">
              </div>-->
              <div class="col-3 mb-1">
                  <span style="color:red">Note: Password must be at least 6 letters or numbers long.</span>
              </div> 
              <br/>
              <button v-if="loading == false" class="btn btn-primary">Submit</button>
              <button v-else class="btn btn-primary" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Submit
              </button>
          </form>
          

        </div>

        <div v-else>
          For Ma Labs employees, you cannot change your password from Ma Labs external website. Please change your password using SOX or Backend Tools.
        </div>

      
    </div> 


  </div>
</template>

<script>
import submenuAccount from '../../components/nav/submenuAccount.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { useStore } from 'vuex'
import { ref } from 'vue'

export default {
  name: 'ChangePassword',
  components: { submenuAccount, BreadcrumbPage },
  setup() {
    const data = ref({
      current_password: {input: null, error: false},
      new_password: {input: null, error: false},
      new_password2: {input: null, error: false},
    })

    const loading = ref(false)
    const status = ref(null)
    const message = ref(null)

    const store = useStore()
    const customer = ref(false)
    const custno = ref(null)
    const company = ref(null)

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "My Account", link: ""})
    breadcrumb_links.value.push({text: "Change Password", link: "ChangePassword"})

    if (!!store.getters.user) {
      if (store.getters.user["groups"].indexOf("Customers") >= 0){
        customer.value = true
        custno.value = store.getters.user["custno"]
        company.value = store.getters.user["company"]
      }
    }

    function validate() {
      data.value.current_password.error = false
      data.value.new_password.error = false
      data.value.new_password2.error = false
      
      if (!data.value.current_password.input) {
        data.value.current_password.error = true
        return false
      }
      if (!data.value.new_password.input) {
        data.value.new_password.error = "This field is required"
        return false
      }
      else if(data.value.new_password.input.length < 6) {
        data.value.new_password.error = "The password is too short."
        return false
      }
      if (data.value.new_password.input != data.value.new_password2.input) {
        data.value.new_password2.error = true
        return false
      }
      return true
      
    }

    const handleSubmit = async () => {
      if (validate() == false){
        return 0
      }

      loading.value = true
      let payload = {
        current_password: data.value.current_password.input,
        new_password: data.value.new_password.input
      }
      let result = await store.dispatch('changepassword', payload)

      if (result.status == 'success') {
        status.value = 'alert-success'
      }
      else {
        status.value = 'alert-danger'
      }
      message.value = result.message
      data.value.current_password.input = ''
      data.value.new_password.input = ''
      data.value.new_password2.input = ''
      loading.value = false
    }

    return {loading,
            status,
            message, 
            custno, 
            company, 
            customer, 
            data,
            handleSubmit,
            breadcrumb_links}
  }
}
</script>

<style scoped>
.submenu-content {
  padding: 0 30px 30px;
}
</style>